.login-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    color: #6E6B7B;
}

.login-logo{
    height: 200px;
    margin-top: 50px;
}

.logo{
    padding: 3% 0 3% 0;
    width: 12%;
}


.login-box{
    box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    border-radius: 10px;
    width: 450px;
}

@media screen and (max-width: 500px) {
    .login-box{
        width: 80%;
    }

    .login-logo{
        width: 80%;
        height: auto;
    }

    .logo{
        width: 50%;
        height: auto;
    }
  }

.login-inputs{
    margin: 10%;
    text-align: center;
}

.login-main-text{
    font-weight: bold;
    color: black;
    margin-bottom: 8px;
}

.credentials-input{
    width: 100%;
    border: 1px solid #D8D6DE;
    box-sizing: border-box;
    border-radius: 10px;
    height: 30px;
    padding-left: 5px;
}

.login-sub-text{
    margin-bottom: 25px;
}

.forgot-password{
    float: left;
}

.checkbox{
    float: left;
}

.login-button{
    display: inline-block;
    margin-top: 35px;
    width: 70%;
    height: 38px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border:#D8D6DE;
    font-size: 15px;
    text-decoration: none;
}

.login-button:hover{
    cursor: pointer;
}


.login-input-text{
    float: left;
}

.break-line{
    width: 20%;
}

.line-break{
    width: 100%; 
    border-bottom: 1px solid black; 
    text-align: center
}

.inside-line-break{
    padding: 0 5px;
}

.signup-button{
    font-weight: bold;
    margin-top: 20px;
    border: 1px solid #B5218A;
    box-sizing: border-box;
    border-radius: 10px;
    color: #B5218A;
    background-color: white;
    padding: 7px 18px 7px 18px;
    height: 38px;
}

button:hover{
    cursor: pointer;
}

.forgot-password a:visited{
    color:turquoise;
}

.forgot-password a{
    text-decoration: none;
}

.sub-inputs{
    margin-top: 10px;
}

.divider {
    color: #6E6B7B;
    font-size: 15px;
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid ; 
    line-height: 0.1em;
    margin: 50px 0 50px; 
 } 
 
 .divider span { 
     background:#fff; 
     padding:0 10px; 
 }