.checklist-container{
    flex-grow: 1;
    margin: 1%;
    border-radius: 9px;
    background-color: white;

    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    -webkit-box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    -moz-box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    border-radius: 9px;
    border: solid rgba(49, 37, 82, 0.1);
}

.table-wrapper{
    overflow: auto;
}

.checklist-table th{
    position: sticky;
    top: 0;
}

.checklist-table td, th {
    padding: 4px 8px;
  }

.checklist-header{
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;
    height: 35px;
    padding: 25px 0 20px 0;
}

.widget-name{
    background-color: RGB(245, 246, 250);
    padding: 10px 5px 10px 10px;
    border-radius: 9px;
    color: #46444f;
}

.new-checklist-button{
    float: right;
    margin-right: 10px;
    margin-top: -10px;
}

.new-checklist-button button{
    width: 140px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    border:#D8D6DE;
    font-size: 15px;
    height: 38px;
    margin: auto;
}

.checklist-history-button{
    color: #4b3e8a;
    margin-right: 15px;
    font-size: 15px;
}

.checklist-history-button:hover{
    cursor: pointer;
}

.order-button{
    float: right;
    margin-left: 50px;
}

.category-text{
    color: #5E5873;
}

.checklist-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
    padding: 10px;
    margin-top: 10px;
}

.checklist-table tr th{
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #B9B9C3;
}

.checklist-table tr th:hover{
    cursor: pointer;
}

.td-start{
    border-width: 2px 0 2px 2px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
}

.td-mid{
    border-width: 2px 0 2px 0;
}

.td-end{
    border-width: 2px 2px 2px 0;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    color: navy;
    display: flex;
    justify-content: space-between;
}


.checklist-table-row:hover{
    border: solid;
    box-shadow: 0px 0px 15px rgba(49, 37, 82, 30%);
    cursor: pointer;
}

.view-checklist span{
    text-decoration: none;
    color: white;
}

.checklist-table-row:hover .view-checklist span{
    color: green;
}

.checklist-table-row td{
    padding: 20px;
    font-size: 14px;
}

@media screen and (max-width: 500px) {
    .checklist-container{
        font-Size: 4vw;
    }
}

.checklist-table tr th{
    padding-left: 20px;
}

.checklist-table-row{
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    -webkit-box-shadow: 0px 0px 5px rgba(49, 37, 82, 0.1);
    -moz-box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    border-radius: 9px;
}

.modal-header{
    background-color: RGB(245, 246, 250);;
    padding: 20px;
}
.close-button{
    float: right;
    background-color: RGB(245, 246, 250);;
}

.close-button:hover{
    cursor: pointer;
}
.checklist-modal-box{
    border-color: black;
    margin: 5%;
    width: 90%;
    height: 65%;
}

.timer-name-input{
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    border-radius: 9px;
    width: 100%;
    padding: 5px;
    margin-bottom: 4%;
}

.timer-text-area{
    margin-bottom: 4%;
    resize: none;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    border-radius: 9px;
    width: 100%;
    font-family: inherit;
    font-size: 13px;
    padding: 5px;;
}

.textbox-header{
    color: #B9B9C3;
    font-size: 12px;
}

.widget-center-aligned{
    text-align: center;
    margin-bottom: 4%;
}

.start-btn:hover{
    cursor: pointer;
}

.icon-pack{
    position: relative;
    top: 5px;
    font-size: 22px;
}

.center-button-area{
    display: flex;
    justify-content: center;
}

.gradient-button{
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 9px;
    color: white;
    border:#D8D6DE;
    font-size: 12px;
    font-weight: bold;
    width: 120px;
    height: 30px;
    margin-left: 4%;
}

.inverse-gradient-button{
    background: white;
    border-radius: 9px;
    font-weight: bold;
    color: #B5218A;
    border:#D8D6DE;
    font-size: 12px;
    width: 120px;
    height: 30px;
}

.widget-title-box{
    display: flex;
    justify-content: space-between;
}

.widget-title-header{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #B9B9C3;
    margin-top: 10%;
}

.order-category{
    background: RGB(245, 246, 250);
    border-radius: 10px;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    padding: 10px;
    width: min-content;
    color: #6E6B7B;
    margin-bottom: 5%;
    margin-top: 5%;
}

.order-item-container{
    display: flex;
    justify-content: space-between;
    border-bottom: solid RGB(245, 246, 250);
}

.order-item-container input{
    height: 25px;
    width: 35px;
    border: 1px solid #D8D6DE;
    box-sizing: border-box;
    border-radius: 9px;
    text-align: center;
}

.order-item-container .icon-pack{
    color: #4B3E8A;
    margin: 0 5px 0 5px;
}

.order-item-container .icon-pack:hover{
    cursor: pointer;
}

.order-buttons{
    margin-top: 8%;
}


.checklist-subheader{
    border-radius: 9px;
    border: solid RGB(245, 246, 250);
    border-width: thin;
    padding-left: 12px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 40px;
}
.sub-header-1{
    font-weight: bold;
}

.qr-code-box{
    border: solid RGB(245, 246, 250);;
    border-width: thin;
    float: left;
    margin-left: 5%;
}

.generated-code-input{
    float: left;
    margin-left: 5%;
    margin-bottom: 15%;
}

.code-output-box{
}

.code-utility-buttons{
    margin-top: 4%;
}

.checklist-modal-input{
    margin-top: 4%;
    background-color: white;
}

.code-utility-buttons button{
    font-weight: bold;
    border: 1px solid #B5218A;
    box-sizing: border-box;
    border-radius: 10px;
    color: #B5218A;
    background-color: white;
    padding: 7px 18px 7px 18px;
    margin-right: 10px;
}

.code-output-box button{
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 6px;
    color: white;
    border:#D8D6DE;
    font-size: 15px;
    width: 90px;
    height: 35px;
    margin-left: -3px;
}

.code-output-box input{
    height: 35px;
    border: 1px solid RGB(245, 246, 250);;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 200px;
    background-color: #f0eef8;
    outline: none;
}

.trashBtn:hover{
    cursor: pointer;
}

.send-email-container{
    margin-top: 20px;
}

.send-email-container input{
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    border-radius: 9px;
    width: 52%;
    padding: 5px;
    margin: 0 10px 0 10px;
}

.send-email-container button{
    width: 50px;
    background: linear-gradient(90deg, #2177b5 0%, #46aded 100%);
    border-radius: 6px;
    color: white;
    border:#D8D6DE;
    font-size: 15px;
    padding: 5px 5px 5px 5px;
}

.widget-name span{
    font-size: 15px;
}


@media screen and (max-width: 500px) {

    .checklist-header{
        overflow: auto;
        min-height: 75px;
    }

    .checklist-history-button{
        display: block;
        margin-bottom: 15px;
    }

    .search-input{
        padding: 0 !important;
        margin-top: 20px;
    }

    .qr-code-image{
        width: 230px!important;
        height: 230px!important;
    }

    .widget-name span{
        font-size: 4vw;
    }

    .new-checklist-button{
        margin-right: 0;
    }
    
    .full-checklist-subtitle{
        font-size: 4vw !important;
    }

    .full-checklist-generate-code button{
        width: 120px;
    }

    .code-output-box input{
        height: 35px;
        border: 1px solid RGB(245, 246, 250);;
        box-sizing: border-box;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        width: 150px;
        background-color: RGB(245, 246, 250);;
    }

}

.history-header{
    margin: 25px 0 0 35px;
    width: 95%;
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;
}

.history-title{
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #551a8b;
    font-size: 28px;
}

.history-back{
    color: #551a8b;
    padding-bottom: 20px;
}