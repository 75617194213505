body{
    
}

.full-checklist-view{
    background-color: RGB(245, 246, 250);
}

.full-checklist-container {
    background-color: white;
    box-shadow: 0px 0px 5px rgba(49, 37, 82, 30%);
    border-radius: 15px;
    margin: 0 10px 0 10px;
    padding: 15px 20px 15px 20px;

}

.full-checklist-container iframe{
    width: 60%;
    height: 1500px;
}

.full-checklist-header-title {
    min-height: 40px;
}

.full-checklist-subtitle{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #4B3E8A;
}


.full-checklist-generate-code{
    float: right;
}

.full-checklist-generate-code button{
    width: 150px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    border:#D8D6DE;
    font-size: 15px;
    padding: 7px 0 7px 0;
}

.full-checklist-header-actions {
    font-weight: bold;
    margin-top: 15px;
    padding-bottom: 10px;
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #4B3E8A;
}

.full-checklist-action-toggle {
    float: right;
    padding: 0 25px 0 0;
}

.full-checklist-tabs{
    color: #4B3E8A;
    width: 100%;
    font-weight: bold;
    margin-top: 2%;
    margin-bottom: 4%;
    font-size: 1.8vh;
    text-align: center;
    display: flex;
    justify-content: center;

}

.tab-item{
    float:left;
    width: 49%;
    text-align: center;
    border-left: solid;
    border-top: solid;
    border-right: solid;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-color: rgba(128, 128, 128, 25%);
    border-width: thin;
    height: 40px;
    padding-top: 10px;
}

.tab-space{
    width: 2%;
    border-bottom: thin solid rgba(128, 128, 128, 0.25) !important;
}

.full-checklist-all-data{
    padding-left: 2%;
    font-family: Montserrat;
    font-style: normal;
}


.tab-item:hover{
    cursor: pointer;
}

.item-timeframe{
    font-weight: bold;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #4B3E8A;
    
    width: 80px;
    background-color: #F5F6FA;
    text-align: center;
    border-radius: 6px;
    margin-bottom: 18px;
}

.selected-icon{
    position: absolute;
}

.item-data{
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.item-data:hover{
    cursor: pointer;
}

.checklist-item-container{
    padding-bottom: 10px;
    margin-bottom: 2%;
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;
}

.task-description{
    padding-left: 2.8em;
    display: inline-block;
}

.full-checklist-add-time-section{
    width: 98%;
    height: 60px;
    border: solid;
    border-width: thin;
    border-radius: 6px;
    color: #4B3E8A;
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    justify-content: center;
    place-items: center;
}

.add-time-button{
    position: relative;
    top: -2px;
}

.full-checklist-add-time-section:hover{
    cursor: pointer;
}

.item-row-div{
    margin: 1% 0 1% 0;
}

.item-row{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.item-row input{
    width: 80%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    margin-right: 2%;
    padding-left: 15px;
}

.search-input{
    padding: 18px 0 0 5px;
}

.search-input input{
    width: 200px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    margin-right: 2%;
    padding-left: 15px;
}

.add-new-header{
    color: #8D818F;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.add-new-header input{
    width: 60%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding-left: 15px;
}

.cancel-button{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.4px;

    color: #B5218A;
    margin-right: 45px;
}

.cancel-button:hover{
    cursor: pointer;
}

.time-options{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

.time-options input{
    width: 6%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding-left: 15px;
}

.checklist-button-container{
    font-size: 0;
}

.time-options button{
    background: #F5F6FA;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    padding: 6px;
    color: #4B3E8A;
}

.remove-time-section{
    float: right;
    margin-right: 10%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #5E5873;
}

.checklist-add-button{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #4B3E8A;
    margin-left: 7%;

}

.checklist-add-button:hover{
    cursor: pointer;
}

.add-item-container{
    padding-bottom: 10px;
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;
    margin-bottom: 2%;
}

.side-text{
    min-width: 6%;
    display: inline-block;
}

.full-checklist-back-action{
    text-decoration: none;
    color: #551a8b;
}

.full-checklist-back-action:hover{
    cursor: pointer;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.success-modal-box{
    padding: 8%;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
    color: rgb(26, 179, 26);
}

.success-modal-header{
    font-size: 27px;
    margin-bottom: 5%;
    color: #636363
}

.success-modal-main-text{
    font-size: 21px;
    margin: 5% 0 10% 0;
    color: #636363
}

.success-modal-btn{
    width: 100%;
    
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 4px;
    color: white;
    border:#D8D6DE;
    font-size: 17px;
    font-weight: bold;
    padding: 7px 0 7px 0;
}

.text-modal-box{
    padding: 4%;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Varela Round', sans-serif;
    text-align: center;
}

.text-modal-header{
    font-size: 27px;
    margin-bottom: 2%;
    color: #636363
}

.text-modal-main-text{
    font-size: 18px;
    margin: 5% 0 5% 0;
    color: #636363;
    width: 99%;
    resize: none;
}

.text-modal-btn{
    width: 100%;
    
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    border:#D8D6DE;
    font-size: 17px;
    font-weight: bold;
    padding: 7px 0 7px 0;
}

.trashBtnModify{
    font-size: 20px;
    bottom: 10px;
}

.trashBtnModify:hover{
    cursor: pointer;
}

.dropbtn {
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: block;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 50px;
    border-radius: 9px;
}

.dropdown-content ul {
    margin: 0;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropbtn ul{
}

.dropbtn ul:hover{
    background-color: #ddd
}

.settings-container{
    overflow: auto;
    padding: 3%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #1B031E;
}

.settings-modify-section{
    float: left;
    width: 48%;
    margin-right: 1%;
}

.settings-section-header{
    border-bottom: solid rgba(128, 128, 128, 25%);
    border-bottom-width: thin;
    padding-bottom: 5px;
    margin-bottom: 4%;
}

.settings-inputs-list span{
    display: inline-block;
    min-width: 20%;
    margin: 0 5% 3% 0;
}

.settings-inputs-list input{
    width: 100%;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding-left: 15px;
    font-size: 14px;
}

.settings-inputs-list div{
    margin-top: 5px;
}

.settings-save-button{
    display: flex;
    justify-content: right;
    margin: 0 3% 2% 0;
}

.settings-save-button button{
    width: 150px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    border:#D8D6DE;
    font-size: 15px;
    padding: 7px 0 7px 0;
}

.time-input{
    min-width: 75px;
}

.navigation-section{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.navigation-buttons button{
    width: 100px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 8px;
    color: white;
    border:#D8D6DE;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 0 7px 0;
}

.modal-input{
    width: 185px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #DDDBE3;
    box-sizing: border-box;
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    margin-right: 2%;
    padding-left: 15px;
}

.confirm-modal-buttons button{
    margin: 12px;
    width: 80px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    border:#D8D6DE;
    font-size: 12px;
    font-weight: bold;
    padding: 7px 0 7px 0;
}

.history-code{
    color: #000080;
}

.pointer:hover{
    cursor: pointer;
}

#history-table tr th:hover{
    cursor: initial;
}

#history-table-row:hover{
    cursor: initial;
}

.signup-header{
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-container{
    display: flex;
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #5E5873;
}

.signup-area{
    box-shadow: 0px 0px 15px rgba(49, 37, 82, 0.1);
    border-radius: 10px;
    padding: 2% 5% 3% 5%;
}

.full-signup-container main{
    margin-bottom: 50px;
}

.footer{
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #4b3e8a;
    text-align: center;
    padding-bottom: 20px;
}

.signup-area div span{
    display: inline-block;
    margin: 10px;
}

.signup-area span{
    margin: 10px;
}

.signup-area input{
    border: 1px solid #D8D6DE;
    box-sizing: border-box;
    border-radius: 10px;
    height: 30px;
    padding: 10px 0 10px 15px;
}

.signup-area div{
    display: flex;
}

.btn-container{
    justify-content: center;
}

.btn-container button{
    margin-top: 35px;
    width: 70%;
    height: 40px;
    background: linear-gradient(90deg, #B5218A 0%, #ED4665 100%);
    border-radius: 10px;
    color: white;
    font-weight: bold;
    border:#D8D6DE;
    font-size: 15px;
    text-decoration: none;
}

.error-text{
    color: red;
    max-width: 170px;
    white-space:pre-wrap;
}

.error-text-recovery{
    color: red;
    white-space:pre-wrap;
}

.tos-error{
    margin-left: 13px;
}

#tos-input{
    height: auto;
}

.tos-popup{
    color: #009b90;
}

.tos-popup:hover{
    cursor: pointer;
}

.tos-modal-box{
    overflow: hidden;
}

.tos-modal-box iframe{
    width: 100%;
    height: 75vh;
}

.loginFooter{
    margin-top: 25px;
    font-size: 12px;
}

.required-text{
    color: red;
}

#email-register{
    width: 100%;
}

#email-register span{
    width: 100%;
}

#email-register input{
    width: 100%;
}

.fluent-icon{
    position: relative;
    top: 2px;
}

.apps-icon{
    position: relative;
    top: 4px;
}

@media screen and (max-width: 500px) {
    .full-checklist-header-title {
        display: flex;
        justify-content: space-between;
    }

    .time-options{
        margin-bottom: 20px;
    }

    .time-options input{
        margin-bottom: 20px;
    }

    .checklist-add-button{
        margin-top: 20px;;
    }

    .full-checklist-generate-code{
        margin-bottom: 20px;
        display: flex;
        justify-content: right;
    }

    .mobile-width{
        width: 100%;
    }

    .cancel-button{
        padding-top: 5px;
    }

    .item-row input{
        width: 60%;
    }

    .side-text{
        margin-right: 10px;
    }
}
