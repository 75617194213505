.main-dashboard{
    background-color: RGB(245, 246, 250);
    min-width: 100%;
    min-height: 100vh;
}

.dashboard-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}