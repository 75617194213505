.top-nav{
    background-color: white;
    color: black;
    font-weight: bold;
}

.top-nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    place-items: center;
    font-size: 15px;
}

.top-nav li {
    float: left;
}

.top-nav li:last-child {
    border-right: none;
}

.top-nav li a {
    display: block;
    text-align: center;
    padding: 16px 16px;
    text-decoration: none;
    color: #551a8b;
}

.nav-item {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #551a8b;
    margin-top: 7%;
    padding-bottom: 10px;
}

.top-nav-logo{
    margin-right: 2%;
}

.nav-item:hover{
    cursor: pointer;
    border-bottom: solid navy;
}

.top-nav img:hover{
    cursor: pointer;
}

.top-nav li a:hover:not(.active) {
    border-bottom: solid navy;
}

.top-nav .active {
}

.top-nav .right-tab{
    margin-left: auto;
}

.nav-icons{
    position: relative;
    top: 2px;
    font-size: 22px;
}

@media screen and (max-width: 500px) {
    .top-nav .right-tab{
        float: right;
    }
    .nav-item{
        display: none;
    }
}

.nav-logo{
    margin-left: 30px;
    width: 85px;
    height: auto;
}

.nav-search{
    width: 100%;
    border: 1px solid #D8D6DE;
    box-sizing: border-box;
    border-radius: 10px;
    height: 30px;
}

.top-nav li b {
    display: block;
    text-align: center;
    padding: 16px 16px;
    text-decoration: none;
}

.notification{
    font-size: 20px;
}

.user{
      margin-right: 15px;
}

#myDropdown a{
    text-align: left;
}

.top-nav #myDropdown{
    right: 15px;
}

.top-nav .myDropdown{
}

.top-nav .navDropbtn{
    font-weight: bold;
}

.navDropbtn .myDropdown{
    margin-top: 6%;
    margin-right: 20px;
    color: #551a8b;
    padding-bottom: 10px;
}

.right-tab .navDropbtn ul:hover{
    border-bottom: solid navy;
}

.navDropbtn .dropdown-content ul:hover{
    border-bottom: none;
    background-color: #ddd;
}

.navDropbtn {
    cursor: pointer;
}